<template>
  <div>
    <page-appbar />
    <router-view
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 60px' : 'margin-top: 52px'
      "
    />
    <page-footer />
  </div>
</template>

<script>
import PageAppbar from "../../components/Appbar/PageAppbar.vue";
import PageFooter from "../../components/Footer/PageFooter.vue";
export default {
  components: {
    PageAppbar,
    PageFooter,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style></style>
